import axios from "@/libraries/Axios";

export const getCartItems = ({basketIdShopCartParamMap}) => axios.post("/p/shopCart/info", {basketIdShopCartParamMap})
export const getCartItemTotal = () => axios.get("/p/shopCart/prodCount")
export const getTotalPay = ({basketIds}) => axios.post("/p/shopCart/totalPay", basketIds)
export const getChangedCart = ({basketId, count, prodId, shopId, skuId}) => axios.post("/p/shopCart/changeItem", {basketId, count, prodId, shopId, skuId})
export const deleteSelected = ({basketIds}) => axios.delete("/p/shopCart/deleteItem", {
    data: basketIds,
} )
export const deleteAll = () => axios.delete("/p/shopCart/deleteAll")
export const getCartItemCount = () => axios.get("/p/shopCart/prodCount")
