import { createI18n } from 'vue-i18n'
// import {getItem} from "@/libraries/Storage";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

// function getLanguageFromStorage(storageType, key) {
//   return getItem(storageType, key);
// }
// let lan = navigator.systemLanguage || navigator.language
let browserLanguage;
if(sessionStorage.getItem('language') !==  null){
  browserLanguage = sessionStorage.getItem('language');
}else{
  browserLanguage = navigator.language || navigator.userLanguage;
  sessionStorage.setItem('language', browserLanguage)
}
let localeLanguage;

if (browserLanguage.includes('zh')) {
  localeLanguage = 'zn';
} else if (browserLanguage.includes('pt')) {
  localeLanguage = 'pt';
} else if (browserLanguage.includes('ru')) {
  localeLanguage = 'ru';
} else if (browserLanguage.includes('tr')) {
  localeLanguage = 'tr';
} else {
  localeLanguage = 'en'; // Default to English
}


export default createI18n({
  locale: localeLanguage || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
