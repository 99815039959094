import axios from "@/libraries/Axios";

//LOGIN
export const authenticateMobile = ({userMobile,loginPassword}) => axios.post("/web/login",{userMobile,loginPassword})
export const authenticateMail = ({userMail,loginPassword}) => axios.post("/web/login",{userMail,loginPassword})

// ICan authorized login
export const icanAuthLogin = ({accessToken}) => axios.post('/ican/login',{accessToken})

//Quick LOgin
export const QuickLogin = ({loginType, loginId, code, region}) => axios.post("/quick/login",{loginType, loginId, code, region})

// google login
export const GoogleSignin =({regionId, idToken}) => axios.post("/google/login", {regionId, idToken})

//fb slogin Api call
export const fbSignin =({regionId, idToken}) => axios.post("/fb/login", {regionId, idToken})

//REGISTRATION
export const smsSend = ({mobile}) => axios.post("/sms/send",{mobile})
export const mailSend = ({mail}) => axios.post("/mail/send",{mail})
export const userRegisterMobile = ({code,invite,loginPassword,nickName,regionId,userMobile}) => axios.post("/user/register",{code,invite,loginPassword,nickName,regionId,userMobile})
export const userRegisterEmail = ({code,invite,loginPassword,nickName,regionId,userMail}) => axios.post("/user/register",{code,invite,loginPassword,nickName,regionId,userMail})

//FORGOT PASSWORD
export const userPasswordResetEmail = ({code,password,mail}) => axios.put("/user/forgetPassword",{code,password,mail})
export const userPasswordResetMobile = ({code,password,mobile}) => axios.put("/user/forgetPassword",{code,password,mobile})

// USER NOTIFICATON
export const getNotifications = (current, size) => axios.get("/p/order/notice/page?current="+current+'&size='+size)
export const unreadNotificationCount = () => axios.get("/p/order/notice/unReadCount")
export const clearAllNotifications = () => axios.post("/userLastAnnouncementReadTime/update")
//Announcementfor user
export const userAnnouncement = (current, size ) => axios.get("/notifications/page?current="+current+'&size='+size)
//Notification Page for not login user
export const notLoginUserNitice =(regionId, current, size) => axios.get("/notifications/notLogin/page?regionId="+regionId+"&current="+current+"&size="+size)
//update announcement as read
export const ReadAnnouncement = () => axios.post("/userLastAnnouncementReadTime/update")

//USer Logout 
export const userLogOut = () => axios.get("/app/logout")