import app from "@/plugins/Vuestic";
import router from './router'
import i18n from './i18n/i18n';
import Pinia from "@/libraries/Pinia";
import Vue3Toasity from 'vue3-toastify';
import Vue3Autocounter from 'vue3-autocounter'
import { createHead } from '@vueuse/head'
import GoogleSignInPlugin from "vue3-google-signin"
import VImageMagnifier from "v-image-magnifier";
import {getItem, setItem} from "@/libraries/Storage";
import { toast } from 'vue3-toastify';

import "@/assets/layout/layout.sass";
import "vuestic-ui/css";
import "@/assets/layout/styles/__override.css";
import 'vue3-toastify/dist/index.css';
const head = createHead();
app.config.globalProperties.$storageSetItem = setItem;
app.config.globalProperties.$storageGetItem = getItem;

app
    .use(i18n)
    .use(router)
    .use(Pinia)
    .use(head)
    .use(
        Vue3Toasity,
        {
          autoClose: 5000,
          theme:'colored',
          position: toast.POSITION.TOP_CENTER,
          limit: 3
        }
    )
    .use(VImageMagnifier, {
      // Change options here
      zoomSize: 150,
      zoomFactor: 2,
      // ...
    })
    .use(GoogleSignInPlugin, {
      clientId: '105369011813-4gfhhoip83ehia3ekn1u53otl8u4okki.apps.googleusercontent.com',
    })
    .component('vue3-autocounter', Vue3Autocounter)
    .mount('#app')
