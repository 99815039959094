<template>
  <div>
    <app-header></app-header>
    <div class="spacer"></div>
    <router-view class="body-view"></router-view>
    <app-footer></app-footer>
    <!-- moved navigation for mobiles here -->
    <div v-if="isSmallScreen" class="footer-navbar" ref="footerNavBar">
      <footer-nav-slot
        :class="'nav-slot-'+index" v-for="(slot,index) in slots" @click="go(slot.to)" :id="slot.id"
        :title="slot.title" :inactiveIcon="slot.inactiveIcon" :activeIcon="slot.activeIcon" :active="slot.active"
        :key="index" @update="updateSlots" :route="slot.to"
      />
      <div class="cart-count-bottom" v-if="cartAmount" style="background-color:red; width:16px; height:16px; position:absolute; display:flex; justify-content:center; align-items: center; border-radius: 50%; top: 3px; right: 100px; font-size: 10px; color:white;">{{ cartAmount }}</div>
      <div class="top-bar"><div id="bar-bar" class="bar"></div></div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/layouts/AppHeader";
import AppFooter from "@/layouts/AppFooter";
import { getCartItemCount } from "@/api/cart"
import {defineAsyncComponent} from "vue";
import  catchTokenError from "@/utils/catchInvalidTokenError"
import {useCartStore} from '@/stores/cartStore'
import { useLoginStore } from "@/stores/userStore";
import {useLoginModalStore} from "@/stores/loginModalStore";
import homeInactive from "@/assets/icons/svg/home-2.svg"
import homeActive from "@/assets/icons/svg/home-2-1.svg"
import discoverInactive from "@/assets/icons/svg/discover.svg"
import discoverActive from "@/assets/icons/svg/discover-1.svg"
import cartInactive from "@/assets/icons/svg/shopping-cart.svg"
import cartActive from "@/assets/icons/svg/shopping-cart-1.svg"
import profileInactive from "@/assets/icons/svg/profile-2.svg"
import profileActive from "@/assets/icons/svg/profile-1.svg"
import chatInactive from "@/assets/icons/svg/chat-inactive.svg"
import chatActive from "@/assets/icons/svg/chat-active.svg"
const FooterNavSlot = defineAsyncComponent(()=>import("@/layouts/SubComponents/FooterNavSlot"))


export default {
  name: "AppLayout",
  components: {
    AppHeader,
    AppFooter,
    FooterNavSlot
  },
  data(){
    return{
      slots:[
        {id:1, title:"Home", activeIcon:homeActive, inactiveIcon:homeInactive, to:"HomeView", active:true, barActive:true},
        {id:2, title:"Discover", activeIcon:discoverActive, inactiveIcon:discoverInactive, to:"Discover", active:false, barActive:false},
        {id:3, title:"Chat", activeIcon:chatActive, inactiveIcon:chatInactive, to:"chat", active:false, barActive:false},
        {id:4, title:"Cart", activeIcon:cartActive, inactiveIcon:cartInactive, to:"ShoppingCart", active:false, barActive:false},
        {id:5, title:"Profile", activeIcon:profileActive, inactiveIcon:profileInactive, to:"profile", active:false, barActive:false},
      ],
      isSmallScreen: window.innerWidth <= 834,
    }
  },
  setup(){
    const loginStore = useLoginStore()
    const isLoggedIn = loginStore.isLoggedIn()
    const userLoginModal = useLoginModalStore();
    const { toggleLoginModal } = userLoginModal;
    return {isLoggedIn,userLoginModal, toggleLoginModal,}
  },
  created(){
    this.setCartAmount();
  },
  mounted() {
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
  computed: {
    cartAmount(){
      const cartStore = useCartStore()
      return cartStore.getCartCount
    },
  },
  methods:{
    updateScreenSize() {
      this.isSmallScreen = window.innerWidth <= 834;
    },
    go(route){
      clearTimeout(this.isScrolling);
      if(route == 'chat'){
        if(this.isLoggedIn){
          this.$router.push({name:route})
        }else{
          this.userLoginModal.toggleLoginModal()
        }
      }else{
        this.$router.push({name:route})
      }
    },
    updateSlots(id){
      this.slots.forEach((slot)=>{
        slot.active = slot.id === id;
      })
    },
    async setCartAmount(){
      if (this.isLoggedIn) {
        try {
          const {data} = await getCartItemCount();
          const cartStore = useCartStore()
          cartStore.setCartItemCont(data)
          this.cartCont = data;
        } catch (error) {
          console.log("Error while setting cart amount : " + JSON.stringify(error));
          catchTokenError(error)
        }
      }
    },
  },
}
</script>

<style scoped lang="sass">
@import "@/assets/layout/styles/__variables.sass"
$active-text-color: #1E7EF9
.spacer
  height: $nav-bar-height
.body-view
  position: relative
  left: 0

.footer-navbar
  display: grid
  grid-template-rows: auto auto
  width: 100%
  position: fixed
  bottom: 0
  left: 0
  transition: transform 0.0001s
  background-color: $dark-color-8
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12)
  z-index: 999
  grid-template-areas: ". bar-area bar-area bar-area bar-area bar-area ."  ". slot-area-1 slot-area-2 slot-area-3 slot-area-4 slot-area-5 ."
  @media screen and (min-width : $md)
    display: none
  @media screen and (max-width : $sm)
    height: 3.75rem
    grid-template-columns: auto 4.5rem 4.5rem 4.5rem 4.5rem 4.5rem auto
  @media screen and (min-width : $sm)
    height: 4.75rem
    grid-template-columns: auto 9rem 9rem 9rem 9rem 9rem auto
  .top-bar
    grid-area: bar-area
    padding: 0
    height: 2px
    margin-bottom: 5px
    @media screen and (max-width : $sm)
      width: 4.5rem * 4
    @media screen and (min-width : $md)
      width: 5rem * 4
    .bar
      @media screen and (max-width : $sm)
        width: 3.8rem
      @media screen and (min-width : $sm)
        width: 8rem
      height: 2px
      background-color: $active-text-color
      transition: 0.5s
  .nav-slot
    @media screen and (min-width : $sm)
      width: 9rem
      display: flex
      justify-content: center
      align-items: center
      transform: scale(1.4)
  .nav-slot-0
    grid-area: slot-area-1
    &.active-slot ~ .top-bar .bar
      @media screen and (max-width : $sm)
        transform: translateX(calc(4.5rem * 0))
        margin-left: 0.25rem
      @media screen and (min-width : $sm)
        transform: translateX(calc(9rem * 0))
        margin-left: 0.4rem
  .nav-slot-1
    grid-area: slot-area-2
    &.active-slot ~ .top-bar .bar
      @media screen and (max-width : $sm)
        transform: translateX(calc(4.5rem * 1))
        margin-left: 0.28rem
      @media screen and (min-width : $sm)
        transform: translateX(calc(9rem * 1))
        margin-left: 0.5rem
  .nav-slot-2
    grid-area: slot-area-3
    &.active-slot ~ .top-bar .bar
      @media screen and (max-width : $sm)
        transform: translateX(calc(4.5rem * 2))
        margin-left: 0.4rem
      @media screen and (min-width : $sm)
        transform: translateX(calc(9rem * 2))
        margin-left: 0.5rem
  .nav-slot-3
    position: relative
    grid-area: slot-area-4
    &.active-slot ~ .top-bar .bar
      @media screen and (max-width : $sm)
        transform: translateX(calc(4.5rem * 3))
        margin-left: 0.2rem
      @media screen and (min-width : $sm)
        transform: translateX(calc(9rem * 3))
        margin-left: 0.5rem
  .nav-slot-3.cart-count-bottom
        background-color: red
        width: 16px
        height: 16px
        position: absolute
        display: flex
        justify-content: center
        align-items: center
        border-radius: 50%
        top: 1px
        right: 5px
        font-size: 10px
        color:white
  .nav-slot-4
    grid-area: slot-area-5
    &.active-slot ~ .top-bar .bar
      @media screen and (max-width : $sm)
        transform: translateX(calc(4.5rem * 4))
        margin-left: 0.4rem
      @media screen and (min-width : $sm)
        transform: translateX(calc(9rem * 4))
        margin-left: 0.5rem
  .active-slot
    font-weight: 600
    transform: scale(1.05)
    @media screen and (min-width : $sm)
      transform: scale(1.45)
</style>
