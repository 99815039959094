<template>
  <div>
    <va-modal class="modal" ref="loginModal" v-model="userLoginModal.loginModal" no-padding >
      <template #content>
        <div class="login-container">
          <div class="img-container flex flex-col md6">
            <va-image
              :ratio="9 / 16"
              :src="loginImg"
              class="login-image"
            />
          </div>
          <div class="flex flex-col md6 sm12">
            <div class="content-container">
              <div class="close-button-container">
                <va-icon
                  :name="'close'"
                  @click="closeModal"
                />
              </div>
              <div class="mobile-header">
                <div class="back-btn-container" @click="closeModal">
                  <svg-icon :icon="BackBtn"/>
                </div>
                <div class="mobile-header-title-container">
                  <div class="card-title"> {{ $t("headerTitle.ICanMall") }}</div>
                </div>
              </div>
              <div class="main-header-container">
                <div class="header-container">
                  <svg-icon :icon="iCanLogo" :width="36" :height="35"></svg-icon>
                  <va-card-title class="card-title"> {{ $t("headerTitle.ICanMall") }} </va-card-title>
                </div>
              </div>
              <!-- <div>
                <h1 class="card-sub-title" v-if="!this.isPasswordLogin">Sign in / Register</h1>
              </div> -->
              <div class="scroll-container">
                <div v-if=" this.isPasswordLogin === true">
                  <p v-if="!this.emailLogin" @click="loginWithEmail" class="login-toggle">{{ $t("subTitles.LoginEmail") }}</p>
                  <p v-if="this.emailLogin" @click="loginWithEmail" class="login-toggle">{{ $t("subTitles.LoginMobile") }}</p>
                </div>
                <va-scroll-container
                  class=""
                  vertical
                >
                  <va-card-content class="card-content-container">
                    <va-form ref="form" @validation="validation = $event" @keydown.enter="userLogin">
                      <!-- Quck Login -->
                      <div v-if="!this.isPasswordLogin" class="input-container">
                        <span class="form-label">{{ $t("formLabels.MobileOrPhone") }}</span>
                        <div class="phone-number-container">
                          <div class="code-select" v-if="isphone==true">
                            <!-- <va-select
                              :rules="this.mobileLogin===true ? codeRule : null"
                              class="mt-2 mb-6"
                              v-model="phoneCode"
                              :options="countryCodes"
                              text-by="value"
                              value-by="key"
                              color="#1E7EF9"
                              :outline="true"
                            /> -->
                            <div class="custom-select" v-if="isphone">
                              <div class="select-selected" @click="toggleDropdown" :class="{ 'select-arrow-active': isOpen }">{{ selectedOption }}</div>
                              <div class="select-items" :class="{ 'select-hide': !isOpen }">
                                <div v-for="(country, index ) in countryCodes" :key="index" @click="selectOption(country.key, index)">
                                  {{ country.value }}
                                </div>
                              </div>
                              <p class="validation-message" v-if="isCodeEmpty">{{ $t('ValidationMsg.SelectCountry') }}</p>
                            </div>
                          </div>
                          <div class="email-phone-div">
                            <input v-model="userInputtest" @input="validateInput" :class="[validationQuick == true? 'email-phone-valid' : 'email-phone'] " placeholder="Enter mobile or email">
                            <p class="validation-message" v-if="validationQuick">{{ validationMessage }}</p>
                          </div>
                        </div>
                      </div>
                      <!-- select component -->
                      <div class="input-container" v-if="!this.isPasswordLogin">
                        <span class="form-label">{{ $t('formLabels.VerificationCode') }}</span>
                        <div class="input-tag">
                          <va-input
                            :rules="verificationCodeRule"
                            v-model="verificationCode"
                            class="mt-2 mb-6 input"
                            inputmode="numeric"
                            :outline="true"
                            color="#1E7EF9"
                            placeholder="Enter verification code"
                          >
                            <template #appendInner>
                              <va-button preset="plain" @click="getCode" :disabled="isButtonDisabled" color="#1E7EF9">
                                <div class="get-code-txt" v-if="!isButtonDisabled">Get</div>
                                <div v-if="isButtonDisabled">{{ +this.countdown+"s" }}</div>
                              </va-button>
                            </template>
                          </va-input>
                        </div>
                      </div>
                      <!-- ............................................. -->
                      <div v-if="this.mobileLogin && this.isPasswordLogin" class="input-container">
                        <span class="form-label">{{ $t("formLabels.MobileNum") }}</span>
                        <div class="phone-number-container">
                          <div class="code-select">
                            <!-- <va-select
                              :rules="this.mobileLogin===true ? codeRule : null"
                              class="mt-2 mb-6 abc"
                              v-model="phoneCode"
                              :options="countryCodes"
                              text-by="value"
                              value-by="key"
                              color="#1E7EF9"
                              :outline="true"
                              style=""
                              height="50"
                            /> -->
                            <div class="custom-select">
                              <div class="select-selected" @click="toggleDropdown" :class="{ 'select-arrow-active': isOpen }">{{ selectedOption }}</div>
                              <div class="select-items" :class="{ 'select-hide': !isOpen }">
                                <div v-for="(country, index ) in countryCodes" :key="index" @click="selectOption(country.key, index)">
                                  {{ country.value }}
                                </div>
                              </div>
                              <p class="validation-message" v-if="isCodeEmpty">{{ $t('ValidationMsg.SelectCountry') }}</p>
                            </div>
                          </div>
                          <va-input
                            :rules="mobileRules"
                            v-model="mobileNumber"
                            inputmode="numeric"
                            color="#1E7EF9"
                            :outline="true"
                            class="mt-2 input"
                            placeholder="Enter mobile number"
                          />
                        </div>
                      </div>
                      <div v-if="this.emailLogin && this.isPasswordLogin" class="input-container">
                        <span class="form-label">{{ $t("formLabels.Email") }}</span>
                        <va-input
                          :rules="emailRules"
                          v-model="loginCredentials.userMail"
                          class="mt-2 mb-6 input"
                          color="#1E7EF9"
                          :outline="true"
                          placeholder="Enter email"
                        />
                      </div>
                      <div class="input-container" v-if=" this.isPasswordLogin">
                        <span class="form-label">{{ $t("formLabels.Passwd") }}</span>
                        <va-input
                          :rules="passwordRule"
                          v-model="loginCredentials.password"
                          :type="isPasswordVisible ? 'text' : 'password'"
                          color="#1E7EF9"
                          :outline="true"
                          placeholder="Enter password"
                          class="mt-2 mb-6 input">
                          <template #appendInner>
                            <va-icon
                              :name="isPasswordVisible ? 'visibility_off' : 'visibility'"
                              size="small"
                              color="gray"
                              @click="isPasswordVisible = !isPasswordVisible"
                            />
                          </template>
                        </va-input>
                      </div>
                    </va-form>
                    <div class="forgot-password-container" v-if=" this.isPasswordLogin">
                      <label class="forgot-password-label" @click="forgotModalView">{{ $t("subTitles.Forgotpwd") }}</label>
                    </div>
                    <div class="buttons-container">
                      <div>
                        <va-button color="#1E7EF9" textColor="#fff" class="main-theme-button" @click="userLogin">{{ $t("header.logIn") }}</va-button>
                      </div>
                      <div>
                        <va-button color="#FFF" textColor="#1E7EF9" border-color="#1E7EF9" class="main-theme-button" @click="PasswordLoginDisplay" v-if="!this.isPasswordLogin">{{ $t("placeholeds.PasswdLogin") }}</va-button>
                      </div>
                      <div>
                        <va-button color="#FFF" textColor="#1E7EF9" border-color="#1E7EF9" class="main-theme-button" @click="QuickLoginDisplay" v-if="this.isPasswordLogin">{{ $t("placeholeds.QuickLogin") }}</va-button>
                      </div>
                      <!-- <div class="divider">
                        <VaDivider>
                          <span class="px-2">or</span>
                        </VaDivider>
                      </div> -->
                      <div class="google-btn">
                        <GoogleSignInButton
                          @success="handleLoginSuccess"
                          @error="handleLoginError"
                          :width="buttonwidth"
                          text="Continue_with"
                          size="large"
                        ></GoogleSignInButton>
                      </div>
                      <div class="fb-btn" v-if="userLoginModal.loginModal">
                        <v-facebook-login-scope v-slot="{ login, }" @login="handleLogin">
                          <!-- Compose HTML/CSS here, otherwise nothing will be rendered -->
                          <button @click="login" class="fb-button" >
                            <svg-icon :icon="FaceBook" class="fb-icon" height="1.35" width="1.3"></svg-icon>
                            {{ 'Log In with Facebook' }}
                          </button>
                        </v-facebook-login-scope>
                      </div>
                    </div>
                  </va-card-content>
                </va-scroll-container>
              </div>
            </div>
          </div>
        </div>
      </template>
    </va-modal>
    <!-- FORGOT PASSWORD MODAL -->
    <va-modal class="forgot-modal" ref="forgotPasswordModal" v-model="forgotModal" no-padding>
      <template #content>
        <div class="forgot-container">
          <div class="reset-header">
            <div class="reset-header-container">
              <div class="left-container">
                <va-icon
                  :name="'arrow_back'"
                  @click="goToLogin"
                />
              </div>
              <div class="center-container">
                <h1 class="h1-container">{{ $t("headerTitle.ChangePwd") }}</h1>
              </div>
              <div class="middle-container">
                <va-icon
                  :name="'close'"
                  @click="closeModal"
                />
              </div>
            </div>
            <label v-if="this.emailLogin">{{ $t("subTitles.PleaceEnterEmail") }}</label>
            <label v-if="!this.emailLogin">{{ $t("subTitles.PleaseEnterMobNum") }}</label>
          </div>
          <div>
            <p v-if="!this.emailLogin" @click="loginWithEmail" class="reset-toggle">{{ $t("subTitles.ResetWithEmail") }}</p>
            <p v-if="this.emailLogin" @click="loginWithEmail" class="reset-toggle">{{ $t("subTitles.ResetWithMobile") }}</p>
          </div>
          <va-scroll-container
            class="scroll-container"
            vertical
          >
            <va-form ref="form" @validation="validation = $event">
              <div class="reset-form">
                <div v-if="this.mobileLogin" class="input-container">
                  <span class="form-label">{{ $t("formLabels.MobileNum") }}</span>
                  <div class="phone-number-container">
                    <div class="code-select">
                      <va-select
                        :rules="this.mobileLogin===true ? codeRule : null"
                        class="mt-2 mb-6"
                        v-model="phoneCode"
                        :options="countryCodes"
                        text-by="value"
                        value-by="key"
                      />
                    </div>
                    <va-input
                      :rules="mobileRules"
                      v-model="mobileNumber"
                      class="mt-2 mb-6"
                      inputmode="numeric"
                      color="#1E7EF9"
                    />
                  </div>
                </div>
                <div v-if="this.emailLogin" class="input-container">
                  <span class="form-label">{{ $t("formLabels.Email") }}</span>
                  <va-input
                    :rules="emailRules"
                    v-model="loginCredentials.userMail"
                    class="mt-2 mb-6"
                  />
                </div>
                <div class="input-container">
                  <span class="form-label">{{ $t("formLabels.NewPasswd") }}</span>
                  <va-input
                    :rules="passwordRule"
                    v-model="loginCredentials.password"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    class="mt-2 mb-6">
                    <template #appendInner>
                      <va-icon
                        :name="isPasswordVisible ? 'visibility_off' : 'visibility'"
                        size="small"
                        color="gray"
                        @click="isPasswordVisible = !isPasswordVisible"
                      />
                    </template>
                  </va-input>
                </div>
                <div class="input-container">
                  <span class="form-label">{{ $t("formLabels.ConfirmPasswd") }}</span>
                  <va-input
                    :rules="conPasswordRule"
                    v-model="conPassword"
                    :type="isConPasswordVisible ? 'text' : 'password'"
                    class="mt-2 mb-6">
                    <template #appendInner>
                      <va-icon
                        :name="isConPasswordVisible ? 'visibility_off' : 'visibility'"
                        size="small"
                        color="gray"
                        @click="isConPasswordVisible = !isConPasswordVisible"
                      />
                    </template>
                  </va-input>
                </div>
                <div class="input-container">
                  <span class="form-label">{{ $t("formLabels.VerificationCode") }}</span>
                  <div class="input-tag">
                    <va-input
                      :rules="verificationCodeRule"
                      v-model="verificationCode"
                      class="mt-2 mb-6 mr-3"
                      inputmode="numeric"
                    />
                    <va-button @click="getresetPwCode" preset="plain">{{ $t("placeholeds.GetCode") }}</va-button>
                  </div>
                </div>
                <div>
                  <va-button color="#1E7EF9" textColor="#fff" class="main-theme-button" @click="forgotPassword"> {{ $t("headerTitle.ChangePwd") }} </va-button>
                </div>
              </div>
            </va-form>
          </va-scroll-container>
        </div>
      </template>
    </va-modal>
  </div>
</template>

<script>
    import { useLoginModalStore } from "@/stores/loginModalStore";
    import { useLoginStore } from "@/stores/userStore";
    import { useRegionStore } from "@/stores/regionStore";
    import LoginImg from "@/assets/images/LoginImg.webp";
    import SvgIcon from "@/components/SvgIcon/SvgIcon";
    import iCanLogo from "@/assets/icons/svg/ican mall icon color.svg";
    import BackBtn from "@/assets/icons/svg/drop left large.svg"
    import FaceBook from "@/assets/icons/svg/Facebook_icon1.svg"
    import CountryJson from "@/utils/country.json";
    import {GoogleSignInButton} from "vue3-google-signin";
    import { authenticateMobile, authenticateMail, smsSend, mailSend, userPasswordResetMobile, userPasswordResetEmail, QuickLogin, GoogleSignin, fbSignin } from "@/api/user";
    import {getRegionList} from "@/api/homePage";
    import { VFBLoginScope as VFacebookLoginScope } from 'vue-facebook-login-component-next'
    import { toast } from 'vue3-toastify'
    export default {
      data(){
          return {
            BackBtn:BackBtn,
            FaceBook:FaceBook,
            isButtonDisabled: false,
            countdown: 60,
            loginType:0,
            loginId:'',
            validationQuick: false,
            isphone: false,
            userInputtest:'',
            isPasswordLogin: false,
            forgotModal: false,
            emailLogin: false,
            mobileLogin: true,
            countryCodes:[],
            loginImg:LoginImg,
            iCanLogo:iCanLogo,
            isPasswordVisible: false,
            isConPasswordVisible: false,
            verificationCode: "",
            phoneCode:"",
            index: this.$storageGetItem('localStorage','phoneCode'),
            mobileNumber: "",
            conPassword: "",
            //countrymobCode:"",
            options: [],
            loginCredentials: {
              userName: "",
              password:"",
              userMail:""
            },
            validation: false,
            codeRule: [(value) => !!value || this.$t('ValidationMsg.SelectCountry')],
            passwordRule: [
              (value) => !!value || this.$t('ValidationMsg.EnterPw'),
              (value) => value.trim() === value || this.$t('ValidationMsg.PWRule'),
            ],
            verificationCodeRule: [
              (value) => !!value || this.$t('ValidationMsg.OTP'),
              (value) => /^[0-9]*$/.test(value) || this.$t('ValidationMsg.ValiedCode')
            ],
            conPasswordRule: [
              (value) => !!value || this.$t('ValidationMsg.Passwd'),
              (value) => value === this.loginCredentials.password || this.$t('ValidationMsg.PwNotMatch')
            ],
            isOpen: false,
            selectedOption: this.value,
            isCodeEmpty: false,
            matchPhone:''
          }
        },
      computed:{
        emailRules(){
            return [
              (value) => !!value || this.$t('ValidationMsg.EnterEmail'),
              (value) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value.replace(/\s/g, "")) || this.$t('ValidationMsg.ValidEmail'),
            ]
        },
        mobileRules(){
          return [
            (value) => /^[0-9]{9,10}$/.test(value) || this.$t('ValidationMsg.ValidNum'),
            (value) => !!value || this.$t('ValidationMsg.MobNum')
          ]
        },
        buttonwidth() {
          if (window.innerWidth <= 330) {
            return '290';
          }else if (window.innerWidth <= 370){
            return '330'
          }else if (window.innerWidth <= 379) {
            return '330';
          }else if (window.innerWidth <= 430) {
            return '357';
          } else {
            return '315';
          }
        },
      },
      components: {SvgIcon, GoogleSignInButton, VFacebookLoginScope,},
        setup() {
            const userLoginModal = useLoginModalStore();
            const authentication = useLoginStore();
            const isLoggedIn = authentication.isLoggedIn()
            const { toggleLoginModal } = userLoginModal;
            const region = useRegionStore()
            const regionId = region.regionId
            const googleTokenLogin = async (idToken) => {
              const signInResponse = await GoogleSignin({ regionId, idToken }) //call backend api and pass region id and id token
                authentication.login(signInResponse.data)
                toggleLoginModal(); //close login modal
                location.reload()
            }
             //........... This is for google signin............
            const handleLoginSuccess = (response) => {
              // This handleLoginSuccess will be triggered when user click on the One Tap prompt
              // This handleLoginSuccess will be also triggered when user click on login button
              // and selects or login to his Google account from the popup
              if(response.credential) { //response.credential is id token
                googleTokenLogin(response.credential) //call backend api
              }
            };
            const handleLoginError = () => {
              console.error("Login failed"); //handle login fail error of google loging
            };
            return { userLoginModal, toggleLoginModal, authentication, regionId, handleLoginError, handleLoginSuccess, isLoggedIn}
        },
        created(){
          localStorage.removeItem('phoneCode')
          getRegionList().then((response) => {
          this.options = response.data;

          const sevedRegion = useRegionStore();
          if (!sevedRegion || !sevedRegion.regionId) {
            return;
          }

          const regionId = sevedRegion.regionId;

          for (let i = 0; i < this.options.length; i++) {  // Changed 'i <= this.options.length' to 'i < this.options.length'
            if (this.options[i].regionId == regionId) {
              this.matchPhone = this.options[i].phoneCode;
              for (let j = 0; j < this.countryCodes.length; j++) {
                if (this.countryCodes[j].key == this.matchPhone) {
                  this.countrymobCode = this.countryCodes[j].value;
                  this.$storageSetItem('localStorage', 'phoneCode', j);

                  const userLoginModal = useLoginModalStore();
                  userLoginModal.setPhoneCodeIndex(j);

                  this.phoneCode = this.countryCodes[j];
                            this.selectedOption = this.countryCodes[j].value;
                }
              }
            }
          }
          this.setPhoneCode();
          }).catch((error) => {
            console.error('Error fetching region list:', error);
          });

          this.getCountryCodes()
          //this.matchCodeAndRegion()
        },
        mounted(){
          if (document.getElementById('facebook-jssdk')) return;

          // Load the Facebook SDK asynchronously
          window.fbAsyncInit = function () {
            window.FB.init({
              appId: '1216308558911359',
              autoLogAppEvents: true,
              xfbml: true,
              version: 'v12.0',
            });
          };

          const js = document.createElement('script');
          js.id = 'facebook-jssdk';
          js.src = 'https://connect.facebook.net/en_US/sdk.js';
          js.async = true;
          document.body.appendChild(js);

        },
        methods: {
          toggleDropdown() {
            this.isOpen = !this.isOpen;
            if(this.selectedOption == null ){
              this.isCodeEmpty = true
            }
          },
          selectOption(option, index) {
            this.selectedOption = this.countryCodes.find(country => country.key === option).value;
            this.index = index
            this.isOpen = false;
          },
          validateInput() {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            const phonePattern = /\b(?:\+\d{1,2}\s?)?(?:\(\d{1,4}\)\s?|[0-9.-])+(?:\s?[0-9.-])+\b(?![^@]*@)/;
            // Check if the input matches either email or phone number pattern
            this.isemail = emailPattern.test(this.userInputtest.replace(/\s/g, ""));
            this.isphone = phonePattern.test(this.userInputtest);
            if(this.isphone && this.userInputtest.replace(/\D/g, '').length > 10) {
              this.validationQuick = true
              this.validationMessage = this.$t('ValidationMsg.ValidNum')
            }else if(!this.isphone && !this.isemail){
              this.validationQuick = true
              this.validationMessage=this.$t('ValidationMsg.ValidEmail')
            }else{
              this.validationQuick = false
            }
          },
          handleLogin(response) {
            // Access the access token from the response object
            const accessToken = response.authResponse.accessToken;
            // Call your login API with the access token
            this.fbTokenLogin(accessToken);
          },
          fbTokenLogin(idToken){
            fbSignin({regionId:this.regionId, idToken:idToken}).then((response)=>{
              this.authentication.login(response.data)
              .then(() =>{
                  toast.success(this.$t('message.LoginSuccess'))
                  this.closeModal()
                  location.reload()
            })
            .catch(() => {
                  toast.warning(this.$t('message.SomethingWrong'),{
                    position: toast.POSITION.TOP_RIGHT,
                  })
                })
            })
          },
          loginWithEmail(){
            this.$refs.form.resetValidation()
            this.emailLogin = !this.emailLogin
            this.mobileLogin = !this.mobileLogin
          },
          QuickLoginDisplay(){
            this.$refs.form.resetValidation()
            this.isPasswordLogin = false;
          },
          goToLogin(){
            this.userLoginModal.toggleLoginModal()
            this.$refs.forgotPasswordModal.hide();
            this.dataClear()
          },
          getCode(){
            this.isButtonDisabled = true;
            if(this.emailLogin){
              mailSend({mail:this.loginCredentials.userMail})
              .then(() => {
                this.isButtonDisabled = true;
                toast.success(this.$t('message.CodeSent'))
              })
              .catch((error) => {
                if(error.status === 400){
                  toast.error(error.data.desc)
                }
              })
            }else if(this.isemail){
              if(this.userInputtest !== ''){
                mailSend({mail:this.userInputtest})
                .then(() => {
                  toast.success(this.$t('message.CodeSent'))
                })
                .catch((error) => {
                  if(error.status === 400){
                    this.isButtonDisabled = false;
                    toast.error(error.data.desc)
                  }
                })
              }
            }else {
              let code = ""
            if(this.countryCodes[this.index].key.length===1){
              code='000'+this.countryCodes[this.index].key
            }
            if(this.countryCodes[this.index].key.length===2){
              code='00'+this.countryCodes[this.index].key
            }
            if(this.countryCodes[this.index].key.length===3){
              code='0'+this.countryCodes[this.index].key
            }
            if(this.isphone){
              this.loginCredentials.userName = code + this.userInputtest
            }else{
              this.loginCredentials.userName = code + this.mobileNumber
            }
            if(this.userInputtest !== '' && /^\d+$/.test(this.loginCredentials.userName) && this.index !== null){
              smsSend({mobile:this.loginCredentials.userName})
              .then(() => {
                this.isButtonDisabled = true;
                toast.success(this.$t('message.CodeSent'))
              })
              .catch((error) => {
                this.isButtonDisabled = false;
                if(error.status === 400){
                  toast.error(error.data.desc)
                }
              })
            }else {
              if(this.index == null){
                this.isCodeEmpty =true
              }else{
                this.validationQuick = true
              }
              this.validationMessage = this.$t('ValidationMsg.ValidNum')
              this.isButtonDisabled = false;
            }
            }
            const countdownInterval = setInterval(() => {
            this.countdown--;
           // If countdown reaches 0, enable the button and reset the countdown
            if (this.countdown === 0) {
              clearInterval(countdownInterval);
              this.isButtonDisabled = false;
              this.countdown = 60;
            }
        }, 1000);
          },
          closeModal() {
            this.$refs.loginModal.hide();
            this.$refs.forgotPasswordModal.hide();
            this.conPassword = ""
            this.isConPasswordVisible = false
            this.dataClear()
          },
          dataClear(){
            this.loginCredentials = {
              userName: "",
              password:"",
              userMail:""
            }
            this.mobileNumber = ""
            this.verificationCode = ""
            this.isPasswordVisible = false
            this.emailLogin = false
            this.mobileLogin = true
            this.isPasswordLogin = false
            this.userInputtest = ""
          },
          getCountryCodes() {
            this.countryCodes = CountryJson['en_List'];
          },
          getresetPwCode(){
            if(this.emailLogin){
              mailSend({mail:this.loginCredentials.userMail})
              .then(() => {
                this.isButtonDisabled = true;
                toast.success(this.$t('message.CodeSent'))
              })
              .catch((error) => {
                if(error.status === 400){
                  toast.error(error.data.desc)
                }
              })
            }
            else {
              let code = ""
            if(this.countryCodes[this.index].key.length===1){
              code='000'+this.countryCodes[this.index].key
            }
            if(this.countryCodes[this.index].key.length===2){
              code='00'+this.countryCodes[this.index].key
            }
            if(this.countryCodes[this.index].key.length===3){
              code='0'+this.countryCodes[this.index].key
            }
            if(this.isphone){
              this.loginCredentials.userName = code + this.userInputtest
            }else{
              this.loginCredentials.userName = code + this.mobileNumber
            } if(this.index !== null){
              smsSend({mobile:this.loginCredentials.userName})
              .then(() => {
                toast.success(this.$t('message.CodeSent'))
              })
              .catch((error) => {
                this.isButtonDisabled = false;
                if(error.status === 400){
                  toast.error(error.data.desc)
                }
              })
            }else{
              this.isCodeEmpty = true
            }
            }
          },
          // matchCodeAndRegion(){
          //   const sevedRegion = useRegionStore();
          //   const regionId = sevedRegion.regionId
          //   for(let i=0; i<this.options.length; i++){
          //     if(this.options[i].regionId == regionId){
          //       const region = this.options[i].phoneCode;
          //       for(let j=0; j<this.countryCodes.length; j++){
          //         if(this.countryCodes[j].key == region){
          //           this.countrymobCode=this.countryCodes[j].value;
          //           this.$storageSetItem('localStorage','phoneCode',j)
          //           this.index = j
          //           const userLoginModal = useLoginModalStore();
          //           userLoginModal.setPhoneCodeIndex(this.index)
          //         }
          //       }
          //     }
          //   }
          //   this.setPhoneCode()
          // },
          userLogin() {
            this.$refs.form.resetValidation()
            this.$refs.form.validate()
            if(this.$refs.form.isValid === true){
                const isDownloadPopup = localStorage.getItem('isDownloadPopup');
                if(this.emailLogin){
                  this.submitMailForm()
                }if(this.isPasswordLogin == false){
                  this.quickUserLogin()
                }else {
                  this.submitMobileForm()
                }
                if (isDownloadPopup === null) {
                    // 'ispopupopen' is not in local storage, set it to true
                    localStorage.setItem('isDownloadPopup', 'true');
                }
            }
          },
          setPhoneCode(){
            this.selectedOption = this.countryCodes[this.userLoginModal.index].value 
            this.phoneCode = this.countryCodes[this.userLoginModal.index]
            if(this.selectedOption == null ){
              this.isCodeEmpty = true
            }
          },
          quickUserLogin(){
            if (this.isphone && this.index !== null) {
              let CCode = ""
              if(this.countryCodes[this.index].key.length===1){
                CCode='000'+this.countryCodes[this.index].key
              }else if(this.countryCodes[this.index].key.length===2){
                CCode='00'+this.countryCodes[this.index].key
              }else if(this.countryCodes[this.index].key.length===3){
                CCode='0'+this.countryCodes[this.index].key
              }
              this.loginId = CCode + this.userInputtest
              this.loginType = 0
            } else {
              this.loginId = this.userInputtest
              this.loginType = 1
            }
            QuickLogin({loginType:this.loginType, loginId: this.loginId, code: this.verificationCode, region:this.regionId })
            .then((response) =>{
                this.authentication.login(response.data)
                .then(() =>{
                    toast.success(this.$t('message.LoginSuccess'))
                    this.closeModal()
                    location.reload()
                })
                .catch(() => {
                  toast.warning(this.$t('message.SomethingWrong'),{
                    position: toast.POSITION.TOP_RIGHT,
                  })
                })
            })
            .catch((error) => {
              if(error.status === 400){
                  toast.error(error.data.desc,{
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }
            })
          },
          submitMobileForm() {
            let code = ""
            if(this.countryCodes[this.index].key.length===1){
              code='000'+this.countryCodes[this.index].key
            }
            if(this.countryCodes[this.index].key.length===2){
              code='00'+this.countryCodes[this.index].key
            }
            if(this.countryCodes[this.index].key.length===3){
              code='0'+this.countryCodes[this.index].key
            }

            this.loginCredentials.userName = code + this.mobileNumber
            authenticateMobile({userMobile:this.loginCredentials.userName, loginPassword:this.loginCredentials.password})
              .then((response) => {
                this.authentication.login(response.data)
                .then(() => {
                  toast.success(this.$t('message.LoginSuccess'))
                  this.closeModal()
                  location.reload()
                })
                .catch(() => {
                  toast.warning(this.$t('message.SomethingWrong'),{
                    position: toast.POSITION.TOP_RIGHT,
                  })
                })
            })
            .catch((error) => {
              if(error.status === 400){
                  toast.error(error.data.desc,{
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }
            })
          },
          submitMailForm() {
            authenticateMail({userMail:this.loginCredentials.userMail, loginPassword:this.loginCredentials.password})
              .then((response) => {
                this.authentication.login(response.data)
                .then(() => {
                    toast.success(this.$t('message.LoginSuccess'),{
                      position: toast.POSITION.TOP_RIGHT,
                    })
                  this.closeModal()
                  location.reload()
                })
                .catch(() => {
                  toast.warning(this.$t('message.SomethingWrong'))
                })
            })
            .catch((error) => {
              if(error.status === 400){
                  toast.error(error.data.desc,{
                    position: toast.POSITION.TOP_RIGHT,
                  })
                }
            })
          },
          forgotPassword(){
            this.$refs.form.resetValidation()
            this.$refs.form.validate()
            if(this.$refs.form.isValid===true){
              if(this.emailLogin){
                this.emailReset()
              } else {
                this.mobileReset()
              }
            }
          },
          emailReset(){
            userPasswordResetEmail({code:this.verificationCode, mail:this.loginCredentials.userMail, password:this.loginCredentials.password})
            .then(() => {
              toast.success(this.$t('message.ResetSuccess'))
              this.$router.push({ name: 'HomeView' });
              this.closeModal()
            })
            .catch((error) => {
              if(error.status === 400){
                toast.error(error.data.desc)
              }
            })
          },
          mobileReset(){
            let code = ""
            if(this.countryCodes[this.index].key.length===1){
              code='000'+this.countryCodes[this.index].key
            }
            if(this.countryCodes[this.index].key.length===2){
              code='00'+this.countryCodes[this.index].key
            }
            if(this.countryCodes[this.index].key.length===3){
              code='0'+this.countryCodes[this.index].key
            }
            this.loginCredentials.userName = code + this.mobileNumber
            userPasswordResetMobile({code:this.verificationCode, mobile:this.loginCredentials.userName, password:this.loginCredentials.password})
              .then(() => {
                toast.success(this.$t('message.ResetSuccess'))
                this.$router.push({ name: 'HomeView' });
                this.closeModal()
              })
              .catch((error) => {
                if(error.status === 400){
                  toast.error(error.data.desc)
                }
              })
          },
          PasswordLoginDisplay(){
              this.isPasswordLogin = true
          },
          forgotModalView(){
            this.forgotModal=true
            this.$refs.loginModal.hide();
            this.dataClear()
          }
        },
    }
</script>

<style scoped lang="sass">
@import "@/assets/styles/__login.sass"

</style>
